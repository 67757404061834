import { t, translate } from "../I18n";
import { TemporarilyUnavailableLabel } from "./TemporarilyUnavailableLabel";
const messages = {
    ASAP: translate(t.client.labels.not_available_now.text),
    OnTime: translate(t.client.labels.not_available_now.preorder_text),
};
export const NotAvailableNowLabel = {
    createLabel() {
        const notAvailableNowOrThenError = (labelParams) => {
            return {
                message: messages[labelParams.fulfillmentTime.type],
                enabledOnClick: true,
                type: "error",
            };
        };
        return (labelParams) => {
            if (labelParams.fulfillmentTime === null) {
                return null;
            }
            if (labelParams.view === "Cart") {
                return null;
            }
            if (TemporarilyUnavailableLabel.createLabel()(labelParams) !== null) {
                return null;
            }
            const reasons = labelParams.reasons;
            if (reasons.includes("Schedule") && !reasons.includes("Preorder")) {
                return notAvailableNowOrThenError(labelParams);
            }
            if (reasons.includes("Preorder")) {
                if (labelParams.productInstances.length === 0) {
                    return notAvailableNowOrThenError(labelParams);
                }
                const preorderSettings = labelParams.productInstancePreorderSettingsService.preorderSettings(labelParams.productInstances[0]);
                if (preorderSettings.hasMultipleRanges ||
                    preorderSettings.isSameDayOnly) {
                    return notAvailableNowOrThenError(labelParams);
                }
            }
            return null;
        };
    },
};
