var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e, _f, _g;
import { makeAutoObservable } from "mobx";
import { inject } from "tsyringe";
import { RSet } from "../collections";
import { Now, RDate } from "../core";
import { perRestaurant } from "../di";
import { LabelsCreatorFactory } from "../labels";
import { IOrderingHours, OnlineOrderingSettingsToken, PurchaseOrderScope, SchedulesAvailableNow, } from "../ordering";
import { PCart } from "./PCart";
import { PRestaurant } from "./PRestaurant";
let PMenuIntegrationContext = class PMenuIntegrationContext {
    constructor(now, labelsCreatorFactory, purchaseOrderScope, cart, restaurant, schedulesAvailableNow, orderingHours, onlineOrderingSettings) {
        this.now = now;
        this.labelsCreatorFactory = labelsCreatorFactory;
        this.purchaseOrderScope = purchaseOrderScope;
        this.cart = cart;
        this.schedulesAvailableNow = schedulesAvailableNow;
        this.orderingHours = orderingHours;
        this.onlineOrderingSettings = onlineOrderingSettings;
        this.menu = restaurant.menu;
        makeAutoObservable(this);
    }
    createLabelsCreator(menuSectionItem) {
        return this.labelsCreatorFactory.create({
            scope: this.purchaseOrderScope,
            productInstances: menuSectionItem.content.instances,
            view: "Menu",
        });
    }
    get isCartOpen() {
        return this.cart.isCartOpen;
    }
    findSection(sectionId) {
        return this.menu.sections.rawFind(sectionId);
    }
    findSchedule(scheduleId) {
        return this.menu.findSchedule(scheduleId);
    }
    isScheduleAvailableNow(schedule) {
        return this.schedulesAvailableNow.includes(schedule.id);
    }
};
PMenuIntegrationContext = __decorate([
    perRestaurant(),
    __param(0, inject(Now)),
    __param(5, inject(SchedulesAvailableNow)),
    __param(6, inject(IOrderingHours)),
    __param(7, inject(OnlineOrderingSettingsToken)),
    __metadata("design:paramtypes", [typeof (_a = typeof RDate !== "undefined" && RDate) === "function" ? _a : Object, typeof (_b = typeof LabelsCreatorFactory !== "undefined" && LabelsCreatorFactory) === "function" ? _b : Object, typeof (_c = typeof PurchaseOrderScope !== "undefined" && PurchaseOrderScope) === "function" ? _c : Object, typeof (_d = typeof PCart !== "undefined" && PCart) === "function" ? _d : Object, typeof (_e = typeof PRestaurant !== "undefined" && PRestaurant) === "function" ? _e : Object, typeof (_f = typeof RSet !== "undefined" && RSet) === "function" ? _f : Object, typeof (_g = typeof IOrderingHours !== "undefined" && IOrderingHours) === "function" ? _g : Object, Object])
], PMenuIntegrationContext);
export { PMenuIntegrationContext };
